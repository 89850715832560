import React, { useEffect, useRef, useState } from "react";
import ETable from "../../../components/editable_table/Etable";
import Slidebar from "../../../components/sidebar/Slidebar";
import {
  Button,
  Input,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  message,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import {
  useDeleteEnquiryMutation,
  useFetchCustomerQuery,
  useFetchEnquiryQuery,
  useFetchSalesUserQuery,
  useUpdateEnquiryMutation,
} from "../../../store/store";
import { Option } from "antd/es/mentions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit, FaEye } from "react-icons/fa";

import { Filter_Enquiry } from "../../../store/mutation/userSlice";
import { ViewModel } from "../ViewModel";

const customer_columns = ["id", "name", "email", "mobile", "address"];
const EnquiryTable = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [updateEnquiry, updateEnquiryResponseInfo] = useUpdateEnquiryMutation();

  const [deleteEnquiry, deleteEnquiryResponseInfo] = useDeleteEnquiryMutation();
  useEffect(() => {
    if (updateEnquiryResponseInfo?.isSuccess) {
      message.success("User Set Successfull");
    }
  }, [updateEnquiryResponseInfo]);
  const navigate = useNavigate();
  const { user, enquiry_page, filter_enquiry } = useSelector(
    (state) => state.user
  );
  const [user_id, setUser_id] = useState("");
  const dispatch = useDispatch();
  const handleChange = (pagination, filters, sorter) => {
    //
    dispatch(Filter_Enquiry(filters));
  };

  const {
    data: data,
    isLoading: loading,
    isFetching: fetch,
    error: error,
  } = useFetchEnquiryQuery({
    user: user?.is_customer ? user_id : "",
    created_by: !user?.is_customer && user_id ? user_id : "",
    page: enquiry_page,
    name: filter_enquiry.name === null ? "" : filter_enquiry?.name[0],
    email: filter_enquiry.email === null ? "" : filter_enquiry?.email[0],
    mobile: filter_enquiry.mobile === null ? "" : filter_enquiry?.mobile[0],
  });
  console.log("data", data);
  const {
    data: customer_data,
    isLoading: customer_loading,
    isFetching: customer_fetch,
    error: customer_error,
  } = useFetchCustomerQuery();
  const {
    data: sales_data,
    isLoading: sales_loading,
    isFetching: sales_fetch,
    error: sales_error,
  } = useFetchSalesUserQuery();
  const [columns, setColumn] = useState();

  const create_client = () => {
    navigate("/create-enquiry");
  };
  const deletethis = (data) => {
    if (data?.id) {
      deleteEnquiry(data?.id);
    }
  };
  const [getData, setGetData] = useState();
  const [showFloorPlan, setShowFloorPlan] = useState(false);
  const performCancel = () => {
    setGetData();
    setShowFloorPlan(false);
  };

  const editfun = (record) => {
    if (!record.floor_plain) {
    } else {
      setGetData(record);
      setShowFloorPlan(true);
    }
  };

  const navi = (data) => {
    // navigate(/design-table/${data.id});
  };
  const navi2 = (data) => {
    navigate(`/design-table/${data.id}`);
  };
  const handleSelect = (data, record) => {
    const {
      customer_id,
      floor_plain,
      proposed_furniture_plan,
      moon_board,
      ...other
    } = record;
    const newData = { customer_id: data, ...other };

    updateEnquiry(newData);
  };
  const handleSelectSales = (data, record) => {
    const {
      created_by,
      floor_plain,
      proposed_furniture_plan,
      moon_board,
      ...other
    } = record;
    const newData = { created_by: data, ...other };

    updateEnquiry(newData);
  };
  const handleSelectCustomerStatus = (e, data, record) => {
    e.stopPropagation();
    const {
      status,
      floor_plain,
      proposed_furniture_plan,
      moon_board,
      ...other
    } = record;
    const newData = { status: data, ...other };
    updateEnquiry(newData);
  };
  useEffect(() => {
    if (user?.is_customer) {
      setUser_id(user?.id);
    } else if (user?.is_superuser || user?.is_admin) {
    } else {
      setUser_id(user?.id);
    }
  }, [user]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
      filter,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined style={{ fontSize: "16px" }} />}
            size="small"
            style={{}}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <>
        <SearchOutlined
          className="SearchOutlined"
          style={{
            color: filtered ? "#ffffff" : undefined,
            fontSize: "16px",
          }}
        />
      </>
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString()?.toLowerCase()?.includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
    filteredValue: filter_enquiry[dataIndex] || undefined,
  });

  useEffect(() => {
    if (user_id && user?.is_customer) {
      setColumn(
        allColumns.filter((e) => customer_columns.includes(e.dataIndex))
      );
    } else if (user_id) {
      setColumn(allColumns);
    } else {
      setColumn(allColumns);
    }
  }, [
    customer_data,
    sales_data,
    data,
    user_id,
    searchText,
    searchedColumn,
    filter_enquiry,
  ]);

  const allColumns = [
    {
      title: "Sr.no",
      dataIndex: "id",
      key: "id",
      //   ...getColumnSearchProps('name'),
      width: 80,
      fixed: "left",
      render: (text, record, index) => {
        return <span>{enquiry_page * 10 - 10 + index + 1}</span>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "100",

      ...getColumnSearchProps("name"),

      //   ...getColumnSearchProps('name'),
    },

    {
      title: "Cx Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      ...getColumnSearchProps("email"),

      // ...getColumnSearchProps("email"),
    },
    {
      title: "Customer Status",
      dataIndex: "status",
      key: "id",
      width: 160,
      render: (text, record, index) => {
        return (
          <>
            <Popconfirm
              disabled={text === "Prospect" || text === "Client" ? true : false}
              title="Make It Prospect Are You Sure?"
              onConfirm={(e) =>
                handleSelectCustomerStatus(e, "Prospect", record)
              }
              onCancel={(e) => e.stopPropagation()}
            >
              <Button
                size="small"
                disabled={
                  text === "Prospect" || text === "Client" ? true : false
                }
              >
                Forward To Prospect
              </Button>
            </Popconfirm>
          </>
        );
      },
    },

    {
      title: "Username",
      dataIndex: "customer_id",
      key: "id",
      width: 160,
      render: (text, record, index) => {
        return (
          <>
            {customer_data ? (
              <Select
                optionFilterProp="children"
                onSelect={(data) => handleSelect(data, record)}
                value={
                  customer_data?.filter(
                    (item) => parseInt(item.id) === parseInt(record.customer_id)
                  )[0]?.username
                }
                style={{ width: "160px" }}
              >
                {customer_data?.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.username}
                  </Option>
                ))}
              </Select>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Sales Executive",
      dataIndex: "created_by",
      key: "id",
      width: 160,
      render: (text, record, index) => {
        return (
          <>
            {sales_data ? (
              <Select
                optionFilterProp="children"
                onSelect={(data) => handleSelectSales(data, record)}
                value={
                  sales_data?.filter(
                    (item) =>
                      parseInt(item.id) === parseInt(record.created_by)
                  )[0]?.username
                }
                style={{ width: "160px" }}
              >
                {sales_data?.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.username}
                  </Option>
                ))}
              </Select>
            ) : null}
          </>
        );
      },
    },

    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
      ...getColumnSearchProps("mobile"),
    },

    {
      title: "Address",
      dataIndex: "address",
      key: "id",
      width: 300,
    },
    {
      title: " ",
      key: "id",
      fixed: "right",
      width: 50,
      render: (record) => (
        <FaEdit
          className="bi-edit"
          style={{ width: "100%", height: "20px" }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/create-enquiry/${record.id}`);
          }}
        />
      ),
    },
    {
      title: " Moon ",
      key: "id",
      fixed: "right",
      width: 50,
      render: (record) => (
        <FaEye
          className="bi-edit"
          style={{ width: "100%", height: "20px" }}
          onClick={(e) => {
            e.stopPropagation();
            editfun(record);
          }}
        />
      ),
    },
  ];
  return (
    <div>
      {/* <Navbar/> */}
      <Slidebar />

      <div className="for-etable">
        <h2 className="e-table-h2">Enquiry Table</h2>
        {user_id && user?.is_customer ? null : (
          <button
            style={{
              padding: "10px 40px",
              borderRadius: "7px",
              border: "none",
              backgroundColor: "#0253a2",
              color: "white",
              fontSize: "15px",
              fontWeight: "bolder",
              cursor: "pointer",
              marginBottom: "30px",
            }}
            onClick={create_client}
          >
            Create New Enquiry
          </button>
        )}
        {loading || !columns || customer_fetch || sales_loading ? (
          <Skeleton />
        ) : (
          <ETable
            size={"small"}
            data={data}
            columns={columns}
            loading={
              fetch ||
              updateEnquiryResponseInfo?.isLoading ||
              customer_fetch ||
              sales_loading
            }
            page={enquiry_page}
            error={error}
            field={"enquiry"}
            navi={navi}
            handleChange={handleChange}
          />
        )}
        {showFloorPlan ? (
          <ViewModel performCancel={performCancel} getData={getData} />
        ) : null}
      </div>
    </div>
  );
};

export default EnquiryTable;
